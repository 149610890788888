import React from "react"

import Box from "../box"
import ContactForm from "../contactForm"

const Actions = () => (
  <>
    <Box pt={[5, 6]}>
      <ContactForm heading={`Want to get in touch?`} pattern={false} />
    </Box>
  </>
  // <Wrapper
  //   as="section"
  //   pt={[5, 6]}
  //   flush
  //   css={css({ "> *": { bg: theme => lighten(0.7, theme.colors.text) } })}
  // >
  //   <Wrapper py={5}>
  //     <Flex
  //       flexWrap="wrap"
  //       css={css({
  //         m: [-3, -4],
  //         "> *": {
  //           p: [3, 4],
  //         },
  //       })}
  //     >
  //       <Box>
  //         <Text children={`Refer a friend`} />
  //       </Box>
  //     </Flex>
  //   </Wrapper>
  // </Wrapper>
)

export default Actions
