import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"

import Actions from "./actions"
import Upper from "./upper"
import Details from "./details"

function Footer() {
  const { communities } = useStaticQuery(
    graphql`
      {
        communities: allPrismicCommunity {
          nodes {
            prismicId
            uid
            data {
              project_status
              name {
                text
              }
            }
          }
        }
      }
    `
  )
  return (
    <footer
      css={css({
        width: "100%",
        overflow: "hidden",
      })}
    >
      <Actions />
      <Upper communities={communities.nodes} />
      <Details />
      <form
        name="generalContactForm"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="fullName" />
        <input type="email" name="email" />
        <input type="phone" name="phone" />
        <textarea type="text" name="message" />
      </form>
    </footer>
  )
}

export default Footer
