import React, { useState } from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"
import { useStaticQuery, graphql } from "gatsby"
import { rgba } from "polished"

import {
  H2,
  H3,
  H6,
  Sidebar,
  Box,
  Button,
  CommunityCard,
  CommunityList,
  Flex,
  H1,
  RichText,
  Wrapper,
} from "components"

const Option = ({ label, onClick, active, ...rest }) => (
  <H2
    as="p"
    fontSize={4}
    css={css({
      "&:not(:last-of-type)": { mb: 3 },
    })}
    {...rest}
  >
    <span
      children={label}
      className={active ? "active" : ""}
      css={css({
        textDecoration: "none",
        color: "inherit",
        backgroundImage: theme =>
          `linear-gradient(to bottom, ${rgba(
            theme.colors.text,
            0.25
          )} 50%, ${rgba(theme.colors.text, 0.25)} 50%)`,
        backgroundRepeat: "repeat-x",
        backgroundSize: "1px 1px",
        backgroundPosition: "0 100%",
        cursor: "pointer",
        "&.active": {
          fontWeight: 800,
          backgroundImage: theme =>
            `linear-gradient(to bottom, ${rgba(
              theme.colors.text,
              0.5
            )} 50%, ${rgba(theme.colors.text, 0.5)} 50%)`,
        },
      })}
      onClick={onClick}
    />
  </H2>
)

function CommunitySelector({ subtext, full }) {
  const { communities } = useStaticQuery(
    graphql`
      {
        communities: allPrismicCommunity {
          nodes {
            prismicId
            uid
            data {
              project_location
              project_featured
              hero_image {
                url
                alt
                fluid(maxWidth: 680) {
                  ...GatsbyPrismicImageFluid
                }
              }
              name {
                text
              }
              tagline {
                text
              }
              project_status
              prices_start_from
              community_color
              body1 {
                ... on PrismicCommunityBody1Floorplan {
                  primary {
                    floorplan_type
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const [locationTypes] = useState(() => {
    let types = []
    communities.nodes.forEach(community => {
      if (
        community.data.project_location &&
        types.indexOf(community.data.project_location) === -1
      ) {
        types.push(community.data.project_location)
      }
    })
    return types
  })
  const [currentFormat] = useState("grid")
  const [activeLocationFilters, setActiveLocationFilters] = useState([])
  const [locationOpen, setLocationOpen] = useState(false)
  const [typeOpen, setTypeOpen] = useState(false)
  const [activeTypeFilters, setActiveTypeFilters] = useState([])

  const toggleLocation = location => {
    let filters = JSON.parse(JSON.stringify(activeLocationFilters))
    if (filters.indexOf(location) !== -1) {
      filters.splice(filters.indexOf(location), 1)
    } else {
      filters.push(location)
    }
    setActiveLocationFilters(filters)
  }

  const toggleType = type => {
    let filters = JSON.parse(JSON.stringify(activeTypeFilters))
    if (filters.indexOf(type) !== -1) {
      filters.splice(filters.indexOf(type), 1)
    } else {
      filters.push(type)
    }
    setActiveTypeFilters(filters)
  }

  return (
    <>
      <Box css={css({ overflow: "hidden" })}>
        <Wrapper as="section" id="communities" pt={full ? [128, 192] : [5, 6]}>
          <Flex
            flexDirection={full && "column"}
            css={
              !full &&
              css({
                flexWrap: "wrap",
                m: -4,
                "> *": {
                  p: 4,
                },
              })
            }
          >
            <Box as="section" width={!full && ["100%", null, null, 1 / 3]}>
              <H1>
                Find a{` `}
                <span
                  css={css({
                    position: "relative",
                    fontWeight: "bold",
                    boxShadow: "inset 0 -1px 0 0 currentColor",
                    cursor: "pointer",
                  })}
                  onClick={() => {
                    setTypeOpen(!typeOpen)
                  }}
                  onKeyUp={event => {
                    if (event.keyCode === 13) {
                      event.preventDefault()
                      setTypeOpen(!typeOpen)
                    }
                  }}
                  // tabIndex={1}
                >
                  {activeTypeFilters.length === 0 ? (
                    "Home"
                  ) : activeTypeFilters.length === 1 ? (
                    activeTypeFilters.toString()
                  ) : (
                    <span>
                      Home<sup>{activeTypeFilters.length}</sup>
                    </span>
                  )}
                  <svg
                    width={14}
                    height={6}
                    viewBox="0 0 14 6"
                    css={css({
                      position: "absolute",
                      top: "50%",
                      right: -2,
                      mt: 1,
                      verticalAlign: "top",
                      color: "text",
                      transform: "translateX(100%) translateY(-50%)",
                      opacity: 0.5,
                    })}
                  >
                    <path d="M7 6l6.928-6H.072L7 6z" fill="currentColor" />
                  </svg>
                </span>
                <br />
                in{` `}
                <span
                  css={css({
                    position: "relative",
                    fontWeight: "bold",
                    boxShadow: "inset 0 -1px 0 0 currentColor",
                    cursor: "pointer",
                  })}
                  onClick={() => {
                    setLocationOpen(!locationOpen)
                  }}
                  onKeyUp={event => {
                    if (event.keyCode === 13) {
                      event.preventDefault()
                      setLocationOpen(!locationOpen)
                    }
                  }}
                  // tabIndex={1}
                >
                  {activeLocationFilters.length === 0 ? (
                    "Locations"
                  ) : activeLocationFilters.length === 1 ? (
                    activeLocationFilters.toString()
                  ) : (
                    <span>
                      Location<sup>{activeLocationFilters.length}</sup>
                    </span>
                  )}
                  <svg
                    width={14}
                    height={6}
                    viewBox="0 0 14 6"
                    css={css({
                      position: "absolute",
                      top: "50%",
                      right: -2,
                      mt: 1,
                      verticalAlign: "top",
                      color: "text",
                      transform: "translateX(100%) translateY(-50%)",
                      opacity: 0.5,
                    })}
                  >
                    <path d="M7 6l6.928-6H.072L7 6z" fill="currentColor" />
                  </svg>
                </span>
              </H1>
              {subtext && (
                <RichText
                  content={subtext}
                  mt={[3, 4]}
                  fontSize={[2, 3, 4]}
                  css={css({
                    maxWidth: 600,
                  })}
                />
              )}
              {!full && (
                <Button
                  children={`All communities`}
                  mt={4}
                  variant="default"
                  as={Link}
                  to={"/communities/"}
                />
              )}
            </Box>
            {full && (
              <Box as="section" py={[4, 5]}>
                {/* <Flex pb={(4, 5)}>
                <Flex
                  onClick={() => {
                    setCurrentFormat("grid")
                  }}
                >
                  <Icon symbol={mdiGrid} />
                  <H6 children={`Grid view`} ml={1} mr={3} />
                </Flex>
                <Flex
                  onClick={() => {
                    setCurrentFormat("list")
                  }}
                >
                  <Icon symbol={mdiViewList} />
                  <H6 children={`List view`} ml={1} />
                </Flex>
              </Flex> */}
                <Box position="relative">
                  <Box
                    position="absolute"
                    top={0}
                    left="50%"
                    width="100vw"
                    height="1px"
                    bg="border"
                    css={css({ transform: "translateX(-50%)" })}
                  />
                </Box>
              </Box>
            )}
            <Box as="section" width={!full && ["100%", null, null, 2 / 3]}>
              {!full &&
                activeLocationFilters.length === 0 &&
                activeTypeFilters.length === 0 && (
                  <H3 children={`Featured communities`} mb={3} />
                )}
              <Flex
                flexWrap="wrap"
                css={css({
                  mx: [-3, -4],
                  my: -3,
                  "> *": {
                    width: ["100%", "50%"],
                    px: [3, 4],
                    py: 3,
                  },
                })}
              >
                {communities &&
                  communities.nodes &&
                  communities.nodes
                    .filter(community => {
                      if (
                        (full ||
                          community.data.project_featured === "Yes" ||
                          activeLocationFilters.length !== 0 ||
                          activeTypeFilters.length !== 0) &&
                        (activeLocationFilters.length === 0 ||
                          activeLocationFilters.indexOf(
                            community.data.project_location
                          ) !== -1) &&
                        community.data.project_status !== "Past" &&
                        community.data.project_status !== "Hidden"
                      ) {
                        return true
                      } else {
                        return false
                      }
                    })
                    .filter(community => {
                      let found = false
                      if (activeTypeFilters.length === 0) {
                        found = true
                      }
                      activeTypeFilters.forEach(filter => {
                        if (community.data.body1) {
                          community.data.body1.forEach(floorplan => {
                            if (floorplan.primary.floorplan_type === filter) {
                              found = true
                            }
                          })
                        }
                      })
                      if (found) {
                        return true
                      } else {
                        return false
                      }
                    })
                    .sort((a, b) => {
                      if (a.data.name && b.data.name) {
                        return a.data.name.text > b.data.name.text ? 1 : -1
                      } else {
                        return 0
                      }
                    })
                    .map((community, index) => {
                      if (currentFormat === "grid") {
                        return (
                          <CommunityCard
                            color={community.data.community_color}
                            status={community.data.project_status}
                            to={"/communities/" + community.uid + "/"}
                            image={community.data.hero_image}
                            alt={community.data.hero_image.alt}
                            location={community.data.project_location}
                            heading={
                              community.data.name && community.data.name.text
                            }
                            subheading={
                              community.data.tagline &&
                              community.data.tagline.text
                            }
                            meta={
                              community.data.prices_start_from &&
                              community.data.prices_start_from
                            }
                            key={`activeCommunity` + community.uid}
                          />
                        )
                      } else {
                        return (
                          <CommunityList
                            status={community.data.project_status}
                            to={"/communities/" + community.uid + "/"}
                            image={community.data.hero_image}
                            heading={
                              community.data.name && community.data.name.text
                            }
                            subheading={
                              community.data.tagline &&
                              community.data.tagline.text
                            }
                            meta={
                              community.data.prices_start_from &&
                              `Starting from ` +
                                community.data.prices_start_from
                            }
                            key={`activeCommunity` + community.uid}
                          />
                        )
                      }
                    })}
              </Flex>
            </Box>
          </Flex>
        </Wrapper>
        <Sidebar
          children={
            <>
              <H6
                children={`Location`}
                pt={[18, 24]}
                px={[3, 4]}
                fontSize={[0, 1, 2]}
                textAlign="right"
              />
              <Box pt={5} px={[3, 4]} css={css({ textAlign: "right" })}>
                {locationTypes
                  .sort((a, b) => {
                    if (
                      a.indexOf("Calgary") !== -1 &&
                      b.indexOf("Calgary") === -1
                    ) {
                      return -1
                    }
                    if (
                      b.indexOf("Calgary") !== -1 &&
                      a.indexOf("Calgary") === -1
                    ) {
                      return 1
                    }
                    return a > b ? 1 : -1
                  })
                  .map(location => (
                    <Option
                      label={location}
                      onClick={() => {
                        toggleLocation(location)
                      }}
                      key={"locationSelectorCommunity" + location}
                      active={activeLocationFilters.indexOf(location) !== -1}
                    />
                  ))}
              </Box>
            </>
          }
          showDialog={locationOpen}
          setShowDialog={setLocationOpen}
          side="right"
        />
        <Sidebar
          children={
            <>
              <H6
                children={`Home Type`}
                pt={[18, 24]}
                px={[3, 4]}
                fontSize={[0, 1, 2]}
                textAlign="right"
              />
              <Box py={5} px={[3, 4]} css={css({ textAlign: "right" })}>
                <Option
                  label={`Townhome`}
                  onClick={() => {
                    toggleType(`Townhome`)
                  }}
                  active={activeTypeFilters.indexOf(`Townhome`) !== -1}
                />
                <Option
                  label={`Bungalow`}
                  onClick={() => {
                    toggleType("Bungalow")
                  }}
                  active={activeTypeFilters.indexOf(`Bungalow`) !== -1}
                />
                <Option
                  label={`Duplex`}
                  onClick={() => {
                    toggleType("Duplex")
                  }}
                  active={activeTypeFilters.indexOf(`Duplex`) !== -1}
                />
                <Option
                  label={`Paired Home`}
                  onClick={() => {
                    toggleType("Paired Home")
                  }}
                  active={activeTypeFilters.indexOf(`Paired Home`) !== -1}
                />
              </Box>
            </>
          }
          showDialog={typeOpen}
          setShowDialog={setTypeOpen}
          side="right"
        />
      </Box>
    </>
  )
}

export default CommunitySelector
