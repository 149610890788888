import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../box"
import Flex from "../flex"
import Text from "../text"
import Wrapper from "../wrapper"

import SocialLinks from "./socialLinks"
import GoogleReviews from "./googleReviews"
import Band from "./band"

function Details() {
  const { contact } = useStaticQuery(
    graphql`
      {
        contact: prismicContact {
          data {
            google_reviews_link {
              url
              link_type
            }
            avg_rating
            review_number
            linkedin {
              url
            }
            instagram {
              url
            }
            facebook {
              url
            }
            youtube {
              url
            }
            twitter {
              url
            }
          }
        }
      }
    `
  )
  return (
    <Wrapper as="section" py={4} borderTop="1px solid" borderColor="border">
      <Flex
        flexDirection={["column", null, "row"]}
        alignItems="center"
        width="100%"
      >
        <Box mr={[0, null, 2]} mb={[3, null, 0]}>
          <GoogleReviews
            link={
              contact.data.google_reviews_link &&
              contact.data.google_reviews_link.url
            }
            reviewCount={contact.data.review_number}
            rating={contact.data.avg_rating}
          />
        </Box>
        <Box mr={[0, null, 2]} mb={[3, null, 0]}>
          <SocialLinks
            facebook={contact.data.facebook}
            twitter={contact.data.twitter}
            instagram={contact.data.instagram}
            youtube={contact.data.youtube}
            linkedin={contact.data.linkedin}
          />
        </Box>
        <Flex
          flex={1}
          flexDirection={["column", null, "row"]}
          alignItems="center"
        >
          <Text mr={[0, 3]} fontSize={0} textAlign="center">
            © {new Date().getFullYear()} Partners Development Group
          </Text>
          <Box flex={1} mt={[3, null, 0]}>
            <Band />
          </Box>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

export default Details
